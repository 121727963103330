/*
|--------------------------------------------------------------------------
| Les vues
|--------------------------------------------------------------------------
|
| Attribution des scripts à éxécuter sur chaque page selon leurs vues
*/

// Importation ----------------------------------------------------------------------------------------------------
import { Accordions } from "./classes/Accordions.js"
import { forceBlankOnExterneAndPdfLinks, hundredVH } from './functions/helper.js'
import { inputsAndTextareaLabel, clearInput, fileUpload, select, textareaHeight, ifErrorTrue, formContact, formEmploi, formSearch, formInfolettre } from './functions/form'
import { overlayCalendar, overlayMenu, overlaySearch, overlayShare } from './functions/overlays.js'
import { featuredNewsBanner, slickHomeBanner, slickFastLinks, slickAlerts, destroySlick, slickShowcase } from './functions/sliders.js'
import { removerHoverImageInLinkDynamic, addSvgToLinks, adjustButtonsMargins, adjustDocLinks } from './functions/dynamic.js'
import { calendar, clickToScrollToBlock, removeHoverOnMobile, alerts, returnBtn, slugifyProvider } from './functions/functions.js'
import { SCROLLFIRE, OBSERVER, OVERLAY } from './main.js'

window.slugifyProvider = slugifyProvider

// Initialisation sur toutes les pages
export class View {
  static init(next) {
    hundredVH()
    removeHoverOnMobile()
    clickToScrollToBlock({ selector: '.js-scroll-to-top', root: next.container })
    overlayMenu(next.container)
    overlaySearch(next.container)
    slickAlerts(next.container)
    alerts()
    forceBlankOnExterneAndPdfLinks()
    inputsAndTextareaLabel(next.container) 
    clearInput()
    fileUpload()
    textareaHeight(next.container)
    ifErrorTrue()
    SCROLLFIRE.init()
    formSearch('#form-search-header', '#submit-search-header')
    formInfolettre('#form-newsletter', '#submit-newsletter')
    removerHoverImageInLinkDynamic()
    addSvgToLinks()
    adjustButtonsMargins()
    adjustDocLinks()
  }

  static leave(current) {
    OVERLAY.destroyAll() 
    SCROLLFIRE.destroy()
    destroySlick(current.container)
  }
}

// Initialisation sur la page de détail
export class Std extends View {
  
  static initStd(next) {
    this.init(next)
  }

  static leaveStd() {
    this.leave()
  }
}

// Initialisation sur la page de détail
export class Style extends View {
  static initStyle(next) {
    this.init(next)
    overlayShare(next.container)
    new Accordions()
    select()
    calendar()
  }

  static leaveStyle() {
    this.leave()
  }
}

// Initialisation sur la page de composantes
export class Components extends View {
  static initComponents(next) {
    this.init(next)
    featuredNewsBanner(next.container)
  }

  static leaveComponents(current) {
    this.leave(current)
  }
}

// Initialisation sur la d'accueil
export class Home extends View {
  static initHome(next) {
    this.init(next)
    slickHomeBanner(next.container)
    slickShowcase(next.container)
    featuredNewsBanner(next.container)
    slickFastLinks(next.container)
    clickToScrollToBlock({ selector: '.js-scroll-to-fast-links', scrollTo: '.js-fast-links-section', root: next.container })
  }

  static leaveHome(current) { 
    this.leave(current)
  }
}

// Initialisation sur la page de contact
export class Contact extends View {
  static initContact(next) {
    this.init(next)
    overlayShare(next.container)
    clickToScrollToBlock({ selector: '.js-scroll-to-form', scrollTo: '.js-form-section', root: next.container })
    ifErrorTrue()
    formContact()
    let accordions = new Accordions({ root: next.container })
    accordions.hash()
  }

  static leaveContact(current) {
    this.leave(current)
  }
}

// Initialisation sur la page d'actualités - liste
export class NewsList extends View {
  static initNewsList(next) {
    this.init(next)
    overlayShare(next.container)
  }

  static leaveNewsList(current) {
    this.leave(current)
  }
}

// Initialisation sur la page d'actualité - détails
export class NewsDetail extends View {
  static initNewsDetail(next) {
    this.init(next)
    overlayShare(next.container)
    featuredNewsBanner(next.container)
  }

  static leaveNewsDetail(current) {
    this.leave(current)
  }
}

// Initialisation sur la page d'événements - liste
export class EventsList extends View {
  static initEventsList(next) {
    this.init(next)
    overlayShare(next.container)
    overlayCalendar(next.container)
    calendar(next.container)
  }

  static leaveEventsList(current) {
    this.leave(current)
  }
}

// Initialisation sur la page d'événement - détails
export class EventDetail extends View {
  static initEventDetail(next) {
    this.init(next)
    overlayShare(next.container)
  }

  static leaveEventDetail(current) {
    this.leave(current)
  }
}

// Initialisation sur la page de résultats de recherche
export class SearchResults extends View {
  static initSearchResults(next) {
    this.init(next)
    overlayShare(next.container)
    formSearch('#form-search-result', '#submit-search-result')
  }

  static leaveSearchResults(current) {
    this.leave(current)
  }
}

// Initialisation sur la page des emplois
export class Jobs extends View {
  static initJobs(next) {
    this.init(next)
    overlayShare(next.container)
    select()
    ifErrorTrue()
    formEmploi()
  }

  static leaveJobs(current) {
    this.leave(current)
  }
}

// Initialisation sur la page de détail d'un emploi
export class JobsDetail extends View {
  static initJobsDetail(next) {
    this.init(next)
    overlayShare(next.container)
    select()
    ifErrorTrue()
    formEmploi()
  }

  static leaveJobsDetail(current) {
    this.leave(current)
  }
}

// Initialisation sur la première page de détail
export class Detail extends View {
  static initDetail(next) {
    this.init(next)
    overlayShare(next.container)
    select()
    featuredNewsBanner(next.container)
    let accordions = new Accordions({ root: next.container })
    accordions.hash()
  }

  static leaveDetail(current) {
    this.leave(current)
  }
}

// Initialisation sur la deuxième page de détail
export class Detail2 extends View {
  static initDetail2(next) {
    this.init(next)
    overlayShare(next.container)
    new Accordions()
    select()
  }

  static leaveDetail2(current) {
    this.leave(current)
  }
}
