/*
|--------------------------------------------------------------------------
| Fonctions
|--------------------------------------------------------------------------
|
| Les divers scripts du site.
*/

import $ from 'jquery'
import anime from 'animejs/lib/anime.es.js'
import { OBSERVER } from './../main.js'
import { getElementOffset } from './helper.js'


// Fonction ajoutant l'événement 'click' qui appellera la fonction 'scrollToBlock'
export const clickToScrollToBlock = (options) => {
    options.duration === undefined ? options.duration = 800              : options.duration
    options.root     === undefined ? options.root     = document         : options.root
    options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
    options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
    options.offset   === undefined ? options.offset   = 0 : options.offset
    
    const onClick = () => scrollToBlock({
        scrollTo: options.scrollTo,
        duration: options.duration,
        easing: options.easing,
        offset: options.offset,
        root: options.root
    })
    
    OBSERVER.add({
        name: 'scrollToBlock',
        event: 'click',
        target: options.selector,
        root: options.root,
        function: onClick
    })

    OBSERVER.on('scrollToBlock')
}

  // Fonction exécutant l'animation du scroll vers son bloc
export const scrollToBlock = (options = {}) => {
    options.duration === undefined ? options.duration = 800              : options.duration
    options.root     === undefined ? options.root     = document         : options.root
    options.scrollTo === undefined ? options.scrollTo = 'html, body'     : options.scrollTo
    options.easing   === undefined ? options.easing   = 'easeInOutQuart' : options.easing
    options.offset   === undefined ? options.offset   = 0 : options.offset
    
    const scrollbar = window.document.scrollingElement || window.document.body || window.document.documentElement
    let element
    if(typeof options.scrollTo === 'string') {
        element = options.root.querySelector(options.scrollTo)

        if(!element) {
        element = document.querySelector(options.scrollTo)
        options.root = document
    }
    } else {
        element = options.scrollTo
    }


    const animation = anime.timeline({
        targets: scrollbar,
        duration: options.duration,
        easing: options.easing
    }).add({ scrollTop: getElementOffset({ element: element, root: options.root }).top + options.offset })
    
    return animation.finished
}
window.scrollToBlock = scrollToBlock

// Fonction permettant d'activer le calendrier
export const calendar = (root = document) => {
  if (!root.querySelector('#calendarOptions')) //s'il n'y a pas d'events
    return

  let eventsDatesList = root.querySelector('#calendarOptions').dataset.list
  let currentDate = root.querySelector('#calendarOptions').dataset.date
  console.log(eventsDatesList)

  $.fn.datepicker.dates['fr'] = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: 'Aujourd\'hui',
    clear: 'Clear',
    format: 'dd/mm/yyyy',
    titleFormat: 'MM yyyy',
    weekStart: 0
  }

  let datesEnabled = eventsDatesList.split(',')
  
  $(root).find('.calendar').datepicker({
    language: 'fr',
    maxViewMode: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: function (date) { // Rendre seulement les dates de la liste d'événements disponibles
      let allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear()
      if(datesEnabled.indexOf(allDates) != -1) return true; else return false
    }
  }).on('changeDate', function(e) {
    let theTimestamp =  Date.parse(e.date)/1000 //Le timestamp du date picker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: {dateFilter: theTimestamp},
      // update: {'evenementlist::eventslist':'#eventsWrapper'},
      // complete: function (data) { data.then(function(data){ eventsLoaded(data) })},
      complete: function (data) { data.then(function(data){ barba.go('/evenements/' + data['date'])})},
    })
    //Fermer l'overlay quand on clique
    $('#overlayCalendar .wrapper a.close span.x').trigger('click')
  })

  $(root).find('.calendar').datepicker('update', currentDate)
}

// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if (/Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) { console.error(ex) }
  }
}

// Fonction gérant les alertes
export function alerts(root = document) {
  const onClick = () => root.querySelector('html').classList.remove('show-alerts')

  OBSERVER.add({ name: 'alerts', event: 'click', function: onClick,  target: '.js-alerts-close',  root: root })
  OBSERVER.on('alerts')
}

export function returnBtn(root = document) {
  let btnReturn = root.getElementsByClassName('btn-return')[0]

  OBSERVER.add({ name:'returnBtn', event: 'scroll', function: onScroll, target: 'document', root: root })
  OBSERVER.on('returnBtn')

  onScroll()

  function onScroll() {
    if (isVisible('.header', root)) {
      btnReturn.classList.add('disable')
    }
    else {
      btnReturn.classList.remove('disable')
    }
  }
}

export function isVisible(e, root = document, threshold, mode) {
  threshold = threshold || 0
  mode = mode || 'visible'

  let element = root.querySelector(e)
  let rect = element.getBoundingClientRect()
  let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  let above = rect.bottom - threshold < 0
  let below = rect.top - viewHeight + threshold >= 0

  return mode === 'above' ? above : (mode === 'below' ? below : !above && !below)
}

export function copyTextToClipboard(text) {
  // if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  // }
  // navigator.clipboard.writeText(text).then(function() {
  //   console.log('Async: Copying to clipboard was successful!')
  // }, function(err) {
  //   console.error('Async: Could not copy text: ', err)
  // })
}

function fallbackCopyTextToClipboard(text) {
  let pos = $(document).scrollTop()

  let textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    document.execCommand('copy')
  } catch (err) { console.log(err)}

  document.body.removeChild(textArea)
  $(document).scrollTop(pos)
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace(/ /g,"-")
  result = result.toLowerCase()

  return result
}