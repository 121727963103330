/*
|--------------------------------------------------------------------------
| Transition avec barba et gestion des vues
|--------------------------------------------------------------------------
|
| C'est ici que les transitions entre les pages sont gérées, on y retrouve
| les différentes vues spécifiques à chaque page. Les vues sont également
| définies ici.
*/

import barba from '@barba/core'
import { returnBtn } from './functions/functions.js'
import { Std, Style, Detail, Detail2, Home, NewsList, NewsDetail, EventsList, EventDetail, Jobs, JobsDetail, SearchResults, Contact, Components } from './global.js'
import { defaultTransition } from './transitions/default-transition.js'

window.barba = barba

export const initBarba = () => {
  barba.hooks.enter(({ current }) => {
    current.container.remove()
		fbq('track', '423824915756176', 'PageView');
  })

  barba.hooks.afterEnter(({ next }) => {
    returnBtn(next.container)
  })

  barba.init({
    debug: true,
    timeout: 5000,
    prevent: ({ el }) => el.classList && el.classList.contains('js-barba-prevent'),
    
    transitions: [
      defaultTransition()
    ],

    views: [{
      namespace: 'stdView',
      beforeEnter({ next }) { Std.initStd(next)  },
      afterLeave ({ current }) { Std.leaveStd(current) }
    },
    {
      namespace: 'styleView',
      beforeEnter({ next }) { Style.initStyle(next)  },
      afterLeave ({ current }) { Style.leaveStyle(current) }
    },
    {
      namespace: 'componentsView',
      beforeEnter({ next }) { Components.initComponents(next)  },
      afterLeave ({ current }) { Components.leaveComponents(current) } 
    },
    {
      namespace: 'homeView',
      beforeEnter({ next }) { Home.initHome(next)  },
      afterLeave ({ current }) { Home.leaveHome(current) }
    },
    {
      namespace: 'contactView',
      beforeEnter({ next }) { Contact.initContact(next)  },
      afterLeave ({ current }) { Contact.leaveContact(current) }
    },
    {
      namespace: 'newsListView',
      beforeEnter({ next }) { NewsList.initNewsList(next)  },
      afterLeave ({ current }) { NewsList.leaveNewsList(current) }
    },
    {
      namespace: 'newsDetailView',
      beforeEnter({ next }) { NewsDetail.initNewsDetail(next)  },
      afterLeave ({ current }) { NewsDetail.leaveNewsDetail(current) }
    },
    {
      namespace: 'eventsListView',
      beforeEnter({ next }) { EventsList.initEventsList(next)  },
      afterLeave ({ current }) { EventsList.leaveEventsList(current) }
    },
    {
      namespace: 'eventDetailView',
      beforeEnter({ next }) { EventDetail.initEventDetail(next)  },
      afterLeave ({ current }) { EventDetail.leaveEventDetail(current) }
    },
    {
      namespace: 'searchResultsView',
      beforeEnter({ next }) { SearchResults.initSearchResults(next)  },
      afterLeave ({ current }) { SearchResults.leaveSearchResults(current) }
    },
    {
      namespace: 'jobsView',
      beforeEnter({ next }) { Jobs.initJobs(next)  },
      afterLeave ({ current }) { Jobs.leaveJobs(current) }
    },
    {
      namespace: 'jobsDetailView',
      beforeEnter({ next }) { JobsDetail.initJobsDetail(next)  },
      afterLeave ({ current }) { JobsDetail.leaveJobsDetail(current) }
    },
    {
      namespace: 'detailView',
      beforeEnter({ next }) { Detail.initDetail(next)  },
      afterLeave ({ current }) { Detail.leaveDetail(current) }
    },
    {
      namespace: 'detail2View',
      beforeEnter({ next }) { Detail2.initDetail2(next)  },
      afterLeave ({ current }) { Detail2.leaveDetail2(current) }
    }
  ]
  })
}
