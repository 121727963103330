/*
|--------------------------------------------------------------------------
| Popup et sidepanel
|--------------------------------------------------------------------------
|
| Ici on retrouve tout ce qui a attrait aux popups et au sidepanels.
*/

import Overlay from '../../libraries/overlay.js'
import { OBSERVER } from '../main.js'
import { Accordions } from '../classes/Accordions.js'
import { copyTextToClipboard } from './functions.js'

// Fonction gérant l'overlay de calendrier
export function overlayCalendar(root = document) {
    let calendar = new Overlay({
      name: 'calendar',
      create: {
        background: false
      },
      click: {
        buttons: {
          open: '.js-open-overlay-calendar',
          close: '.js-close-overlay-calendar',
        }
      },
      options: {
        speed: 800,
        root: root
      }
    })
  
    calendar.init()
  }

// Fonction gérant l'overlay Menu
export const overlayMenu = (root = document) => {
  let menu = new Overlay({
    name: 'menu',
    events: {
      close: true
    },
    create: { close: false },
    click: { buttons: { toggle: '.js-toggle-overlay-menu', switch: '.js-toggle-overlay-search' }},
    options: {
      speed: 800,
      goToSelector: 'html, body', 
      root: root
    }
  })
  
  menu.init()

  const mainLevel = new Accordions({
    name: 'MainLevel',
    containerClass: 'js-accordions-main-level',
    accordionClass: 'js-accordion-main-level',
    toggleButtonClass: 'js-accordion-toggle-main-level',
    contentClass: 'js-accordion-content-main-level',
    openedClass: 'js-accordion-opened-main-level',
    root: root
  })

  const secondLevel = new Accordions({
    name: 'SecondLevel',
    containerClass: 'js-accordions-second-level',
    accordionClass: 'js-accordion-second-level',
    toggleButtonClass: 'js-accordion-toggle-second-level',
    contentClass: 'js-accordion-content-second-level',
    openedClass: 'js-accordion-opened-second-level',
    root: root
  })

  const onClose = () => {
    // mainLevel.closeAll()
    secondLevel.closeAll()
  }

  const onCloseMainLevel = () => {
    secondLevel.closeAll()
  }

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseOverlayMenu',
    function: onClose
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.add({
    name: 'overlayMenu',
    event: 'onCloseAllAccordionsMainLevel',
    function: onCloseMainLevel
  })

  OBSERVER.on('overlayMenu')
}

// Fonction gérant l'overlay Search
export const overlaySearch = (root = document) => {
  let search = new Overlay({
    name: 'search',
    create: { close: false },
    events:  { openComplete: true },
    click: { buttons: { toggle: '.js-toggle-overlay-search', switch: '.js-toggle-overlay-menu' }},
    animations: {
      addTransition: true,
      styles: [{ property: 'height', value: '100%', easing: 'easeInOutQuart' }]
    },
    options: {
      closeOnResize: false,
      speed: 800,
      goToSelector: 'html, body',
      root: root,
    }
  })
  
  const onOpenComplete = () => { searchInput.focus() }

  search.init()

  const searchInput = root.querySelector('.js-input-to-clear')
  OBSERVER.add({ name:'overlaySearch', event: 'onOpenCompleteOverlaySearch', function: onOpenComplete })
  OBSERVER.on('overlaySearch')
}

// Fonction gérant l'overlay de partage
export const overlayShare = (root = document) => {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  let share = new Overlay({
    name: 'share',
    click: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.close-overlay',
      }
    },
    options: {
      speed: 800,
      root: root
    }
  })

  share.init()

  $(root).find('#copyShareBtn').click( function() {
    let link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('L’adresse URL a bien été copiée sur votre presse-papiers.')
    return false
  })

  $(root).find('.rubric-share').on('click', function(e) {console.log(e.currentTarget.id)
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Municipalité de Chertsey&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $(root).find('.overlay-share__background, .overlay-share__close').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $(root).find('#emailShareBtn').attr('href', 'mailto:?Subject=Municipalité de Chertsey&body=' + window.location.href)
    $(root).find('#facebookShareBtn').attr('data-url', window.location.href)
    $(root).find('#twitterShareBtn').attr('data-url', window.location.href)
  })
}