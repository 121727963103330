//Enlever effet hover lien dynamic qui contient une image
export function removerHoverImageInLinkDynamic() {
    let blocsDynamic = document.querySelectorAll('.dynamic')

    if(blocsDynamic.length){
        for(var intBloc = 0; intBloc < blocsDynamic.length; intBloc++){
            let linkElements = blocsDynamic[intBloc].getElementsByTagName('A')

            for(var intLink = 0; intLink < linkElements.length; intLink++){
                if (linkElements[intLink].querySelectorAll('img').length){
                    linkElements[intLink].classList.add('link-image')
                }
            }
        }
    }
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
    let themePath = window.config.theme_path
    let notLink = ':not(.a-style-document):not(.contact__phone-link)'
    let linksExternal = document.querySelectorAll('.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])' + notLink + '')

    linksExternal.forEach(function (link) {
        if (!link.querySelector('img')) {
            let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
                useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
            useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-external`)
            svgElem.appendChild(useElem)
            link.appendChild(svgElem)
        }
    })

    let linksDocuments = document.querySelectorAll('.dynamic a[href$=".pdf"]' + notLink + ', .dynamic a[href$=".doc"]' + notLink + ', .dynamic a[href$=".zip"]' + notLink + '')
    linksDocuments.forEach(function (link) {
        if (!link.querySelector('img')) {
            let svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
                useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use')
            useElem.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `/themes/${themePath}/assets/medias/images/icons/symbols.svg#ico-download`)
            svgElem.appendChild(useElem)
            link.appendChild(svgElem)
            link.setAttribute('target', '_blank')
        }
    })
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins() {
    let i,
        buttons = document.querySelectorAll('.dynamic p > a.btn-simple, .dynamic p > a.btn-impact'),
        buttonsLength = buttons.length

    for(i=0; i<buttonsLength; i++)
        buttons[i].parentNode.classList.add('buttons')

    let buttonsParagraph = document.querySelectorAll('.dynamic p.buttons'),
        buttonsParagraphLength = buttonsParagraph.length

    for(i=0; i<buttonsParagraphLength; i++) {
        if(buttonsParagraph[i].previousElementSibling !== null)
            if(!buttonsParagraph[i].previousElementSibling.classList.contains('buttons'))
                buttonsParagraph[i].classList.add('buttons--first')
        if(buttonsParagraph[i].nextElementSibling !== null)
            if(!buttonsParagraph[i].nextElementSibling.classList.contains('buttons'))
                buttonsParagraph[i].classList.add('buttons--last')
    }
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustDocLinks() {
    let i,
        buttons = document.querySelectorAll('.dynamic p > a.a-style-document'),
        buttonsLength = buttons.length

    for(i=0; i<buttonsLength; i++)
        buttons[i].parentNode.classList.add('link-document')

    let buttonsParagraph = document.querySelectorAll('.dynamic p.link-document'),
        buttonsParagraphLength = buttonsParagraph.length

    for(i=0; i<buttonsParagraphLength; i++) {
        if(buttonsParagraph[i].previousElementSibling !== null)
            if(!buttonsParagraph[i].previousElementSibling.classList.contains('link-document'))
                buttonsParagraph[i].classList.add('link-document--first')
        if(buttonsParagraph[i].nextElementSibling !== null)
            if(!buttonsParagraph[i].nextElementSibling.classList.contains('link-document'))
                buttonsParagraph[i].classList.add('link-document--last')
    }
}