/*
|--------------------------------------------------------------------------
| Sliders
|--------------------------------------------------------------------------
|
| Création de sliders.
*/

import $ from 'jquery'
import { OBSERVER, SCROLLFIRE } from '../main'
import { isMobile } from '../functions/helper.js'

export const slickHomeBanner = (root = document) => {
  $(root).find('.js-home-banner-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-home-banner-slider-dots',
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  })
}

export const featuredNewsBanner = (root = document) => {

  $(root).find('.js-featured-news-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-featured-news-slider-dots',
    speed: 600,
    variableWidth: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
  })

  $(root).find('.js-featured-news-slider').on('afterChange', () => {
    // SCROLLFIRE.init()
  })

  $(root).find('.js-featured-news-slider-prev').on('click', () => {
    $(root).find('.js-featured-news-slider').slick('slickPrev')
  })

  $(root).find('.js-featured-news-slider-next').on('click', () => {
    $(root).find('.js-featured-news-slider').slick('slickNext')
  })

  slickSlideCount('#featured-news-slider', '#featured-news-nav .slideNumber .current', '#featured-news-nav .slideNumber .total')
}

export const slickFastLinks = (root = document) => {
  $(root).find('.js-btn-fast-links-slider').slick({
    autoplay: true,
    arrows: false,
    dots: false,
    centerPadding: "10px",
    draggable: false,
    infinite: true,
    pauseOnHover: false,
    swipe: false,
    touchMove: false,
    vertical: true,
    speed: 1000,
    autoplaySpeed: 2000,
    useTransform: true,
    cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
    adaptiveHeight: true,
  })
}

// Fonction permettant d'afficher la slide active et le nombre total de slide
function slickSlideCount(slickElement, currentElement, totalElement)  {
  $(slickElement).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $(currentElement).html(slidePosition)
  })

  let total = $(slickElement + ' .slick-slide:not(.slick-cloned)').length
  let displayedTotal = total < 10 ? '0' + total : total
  $(totalElement).html(displayedTotal)
}

// Création du slick de l'alerte'
export function slickAlerts(root) {
  if (root.querySelectorAll('.js-alerts-slider').length != 0) {
    $(root).find('.js-alerts-slider').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      draggable: true,
      speed: 500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
    })

    let total = $(root).find('.js-alerts-slider .slick-slide:not(.slick-cloned)').length
    let displayedTotal = total < 10 ? + total : total
    $(root).find('.js-alerts-total').html(displayedTotal)

    $(root).find('.js-alerts-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var slidePosition = nextSlide + 2 < 10 ? + (nextSlide + 1) : (nextSlide + 1)
      $(root).find('.js-alerts-current').html(slidePosition)
    })

    $(root).find('.js-alerts-slider-prev').on('click', () => {
      $(root).find('.js-alerts-slider').slick('slickPrev')
    })
  
    $(root).find('.js-alerts-slider-next').on('click', () => {
      $(root).find('.js-alerts-slider').slick('slickNext')
    })
  }
}

// Creéation du slick de la vitrine de bannière
export const slickShowcase = (root = document) => {
  $(root).find('.js-showcase-text-slider').slick({
    dots: true,
    arrows: false,
    appendDots: '.js-showcase-slider-dots',
    speed: 1000,
    fade: false,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    rtl: true,
    css: 'cubic-bezier(0.65, 0, 0.35, 1)',
    asNavFor: $('.js-showcase-image-slider'),
  })
  $(root).find('.js-showcase-image-slider').slick({
    dots: true,
    arrows: false,
    appendDots: false,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: false,
    asNavFor: $('.js-showcase-text-slider'),
  })

  let slider = $('.js-showcase-text-slider')

  fixSlickAnim(slider)
}

const fixSlickAnim = (slider) => {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (slick.currentDirection == 1)
      direction = 'left'
    else
      direction = 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}

export function destroySlick(root = document, delay = 490) {
  $(root).find('.slick-initialized').slick('unslick')
}